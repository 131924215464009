import {authReducer} from './authReducer';
import {combineReducers} from '@reduxjs/toolkit'
import {reducer as notifications} from 'react-notification-system-redux';
import theme from './themeReducer';


import enums from "./enumsReducers";
import usosMultiples from "./usosMultiplesReducers";
import paises from "./paisesReducers";
import monedas from "./monedasReducers";
import rutas from "./rutasReducers";
import agentes from "./agentesReducers";
import roles from "./rolesReducers";
import gastos from "./gastoReducers";
import ingresos from "./ingresosReducers";
import companias from "./companiasReducers";
import zonasHorarias from "./zonasHorariasReducers";
import carteras from "./carterasReducers";
import cajas from "./cajasReducers";
import pagos from "./pagosReducers";
import plantillas from "./plantillasReducers";
import clientes from "./clientesReducer";
import prestamos from "./prestamosReducer";
import solicitudes from "./solicitudesReducer";
import dashBoard from "./dashBoardReducers";
import feriados from "./feriadosReducers";
import appUsers from "./appUsersReducers";
import adminCuadres from "./adminCuadresReducers";
import notificaciones from "./notificacionesReducers";
import ahorros from "./ahorrosReducers";
import ahorrosTransacciones from "./ahorrosTransaccionesReducers";

const combinedReducer = combineReducers({
    auth: authReducer,
    theme,
    agentes,
    roles,
    usosMultiples,
    paises,
    monedas,
    rutas,
    gastos,
    ingresos,
    companias,
    zonasHorarias,
    carteras,
    cajas,
    pagos,
    enums,
    plantillas,
    clientes,
    prestamos,
    solicitudes,
    ahorros,
    ahorrosTransacciones,
    dashBoard,
    feriados,
    notificaciones,
    appUsers,
    adminCuadres,
    notifications
})

const rootReducer = (state:any, action:any) => {
    if (action.type === 'authReducer/userLogout') {
        state = undefined
    }
    return combinedReducer(state, action)
}

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
