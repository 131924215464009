import Strings from "../assets/strings";
import {IKeyValue} from "../redux/types/Icommon";
import _ from "lodash";

export const COMPANIA_PARAMETRO = {
    ocultarInteresAmortizacion:  1,
    modificarMora: 11,
    permitirCerrarCuotaIncompleta: 13

}
export const PERSMISOS = {
    dashboard:1,
    mapa:2,
    notificaciones:3,
    enviarCorreo:4,

    clientes:10,
    clientesCrear:11,
    clientesActualizar:12,
    clientesEliminar:13,
    clientesImportar:14, //nuevo
    clientesExportar:15, //nuevo

    prestamos:20,
    prestamosCrear:21,
    prestamosActualizar:22,
    prestamosEliminar:23,
    prestamosAjustes:24,
    prestamosCrearNotas:25,
    prestamosEliminarNotas:26,
    prestamosGenerarContrato:27, //nuevo
    prestamosReenganche:28, //nuevo
    prestamosExportar:29, //nuevo

    pagos:30,
    pagosCrear:31,
    pagosEliminar:32,
    pagosCambiarFecha:33,
    pagosCambiarCaja:34,
    pagosDescuento:35,
    pagosReImprimir:36,
    pagosPermitirPagoIncompleto:37, //Permite que el agente, pueda cierre cuotas parciales.
    pagoInicial:38,
    pagosExportar:39, //nuevo

    cajas:40,
    cajasCrear:41,
    cajasActualizar:42,
    cajasEliminar:43,
    cajasRealizarCierres:44,
    cajasTransferencias:45,

    gastos:50,
    gastosCrear:51,
    gastosEliminar:52,

    solicitudes:60,
    solicitudesAprobar:61,
    solicitudesRechazar:62,
    verTodas:63, //Por Defecto, los agentes solo pueden ver las solicitudes de ellos.-
    solicitudesActualizar:64,
    solicitudesEliminar:65, //nuevo
    solicitudesExportar:67, //nuevo

    rutas:70,
    rutasCrear:72,
    rutasActualizar:73,
    rutasEliminar:74,
    rutasOrdenar: 75,

    plantillas: 80,
    plantillasCrear: 81,
    plantillasActualizar:82,
    plantillasEliminar:83,
    plantillasImprimir:84,

    carteras: 90,
    carterasCrear: 91,
    carterasActualizar: 92,
    carterasEliminar: 93,

    reportesResumenGeneral: 100, //nuevo
    reportesIngresos: 101, //nuevo
    reportesEgresos:102, //nuevo
    reportesAntiguedad:103, //nuevo

    feriado:110,
    feriadoCrear: 111,
    feriadoEliminar: 112,

    ingresos: 120,
    ingresosCrear: 121,
    ingresosEliminar: 122,




    ahorros: 130,
    ahorrosCrear: 131,
    ahorrosActualizar: 132,
    ahorrosEliminar: 133,
    ahorrosExportar: 134,
    ahorrosNotasCrear: 135,
    ahorrosNotasEliminar: 136,

    ahorrosTransacciones: 140,
    ahorrosTransaccionesCrear: 141,
    ahorrosTransaccionesEliminar: 142,
    ahorrosTransaccionesExportar: 143,

};
export const PERSMISOS_USUARIOS = {
    webApp:500,
    cilosPago:501,
    documentos:502,
    geoMapa:503,
    cajas:504,
    rutas:505,
    gastos:506,
    carteras:507,
    solicitudes:508,
    plantillas:509,
    garantias:510,
    alquilerInmueble:5011
}
export const MODULO_USUARIO = {
    webApp:1,
    cilosPago:2,
    documentos:3,
    geoMapa:4,
    cajas:5,
    rutas:6,
    gastos:7,
    carteras:8,
    solicitudes:9,
    plantillas:10,
    garantias:11,
    alquilerInmueble:12,
    agentes:13,
    pagoRapido:14,
    ocultarPieRecibo:15,
    ocultarPieEstadoCuenta:16,
    whatsappBot: 17,
    enviarCorreo: 18,
    ingresos:19
}
export const DIAS_SEMANA = {
    lunes: 1,
    martes: 2,
    miercoles: 3,
    jueves: 4,
    viernes: 5,
    sabado: 6,
    domingo: 0,
}

export const TIPOUSUARIO = {
    titular: 1,
    agente: 2,
    admin: 99,
}
export const TIPOPLAN = {
    gratis: 1,
    basico: 2,
    profesional: 3,
    avanzado: 4,
    basicoPro: 5,
    personal: 6,
    ilimitado: 7,
    comercial: 8,
    empresarial: 9,
}
export const TIPO_TRANSACCION_AHORRO = {
    deposito: 1,
    retiro: 2,
}

export const TIPOPAGO={
    // PAGO_RAPIDO:9,
    CUOTA_COMPLETA: 1,
    // PAGO_PARCIAL: 2,
    ABONO_CAPITAL: 3,

    AUSTE_CAPITAL: 4,
    SOLO_INTERES: 5,
   // PAGO_MORA:7,

    SALDAR:6,
    PAGO_BORRADOR:8,
    PAGO_INICIAL:10
};
export const TIPO_MOVIMIENTO={
    BALANCE_INICIAL:1,
    PAGO:2,
    ANULACION_PAGO:3,
    PRESTAMO:4,
    CANCELACION_PRESTAMO:5,
    AJUSTE_CAPITAL:6,
    ANULACION_AJUSTE_CAPITAL:7,
    AJUSTE_CAJA:8,
    GASTO:9,
    ANULACION_GASTO:10,
    TRANSFERENCIA_CAJA:11,
    GASTOS_LEGALES:12,
    REENGANCHE_PRESTAMO:13,
    INGRESO:14
};

export const TIPO_NOTIFICACION={
     SOLICITUD: 1,
     PAGO_CLIENTE: 2,
     NOTA_PRESTAMO: 3,
     MENSAJE_SISTEMA: 4,
     NUEVO_USUARIO: 5,
     OTROS: 99

};
export const TIPO_AMORTIZACION={
    CUOTA_FIJA:	1,
    DISMINUIR_CUOTA: 2,
    INTERES_FIJO: 3,
    CAPITAL_AL_FINAL: 4,
};
export const CICLOS_PAGO={
    DIARIO: 1,
    INTERDIARIO: 2,
    SEMANAL: 7,
    BISEMANAL: 14,
    QUINCENAL: 15,
    QUINCEFINDE_MES: 16,
    MENSUAL: 30,
    ANUAL: 365,
};
export const ESTADO_PRESTAMO={
    ACTIVO: 1,
    SALDADO: 2,
    ANULADO: 3,
    INCOBRABLE: 4,
    REENGANCHADO: 5
};
export const ESTADO_SOLICITUD={
    PENDIENTE: 1,
    APROBADO: 2,
    RECHAZADO:3
};

export const SITUACION_PRESTAMO={
    AL_DIA: 1,
    PENDIENTE:2,
    MORA:3,
    CUOTAS_VENCIDA:4
};


export const getValueString = (constant:any = {},value?:number, trans= true) =>{
    let prop = "";
    if (value !== undefined && Object.keys(constant)?.length) {
        Object.keys(constant).forEach((pro)=>{
            if(constant[pro] === value){
                prop = trans ? ((Strings as any)[_.camelCase(pro)] || pro) : pro;
            }
        })
    }
    return prop;
}
export const objToKeyValue = (constant:any = {}) =>{
    let arr:IKeyValue[] = [];
    Object.keys(constant).forEach((pro,i)=>{
        arr.push({
            key:constant[pro],
            value:(Strings as any)[_.camelCase(pro)] || pro
        })
    })
    return arr;
}


export const mapApiOptions = {
    id: 'google-map-script',
    libraries:["places"],
    googleMapsApiKey: "AIzaSyAX7wSF1C-XJwlBRJUP2c4W2l89lq4mblY"//"AIzaSyDpi0iwVulr11eYWuk66OgcW4w-tq4sAh4",
}
export const firebaseConfig = {
    config: {
        apiKey: "AIzaSyDC9Vs5AXexiDePs7__NrF7bz3eS6uUceQ",
        authDomain: "prestamistapp2.firebaseapp.com",
        projectId: "prestamistapp2",
        storageBucket: "prestamistapp2.appspot.com",
        messagingSenderId: "1072506057274",
        appId: "1:1072506057274:web:6733c5cbf99407b4fefb03",
        measurementId: "G-928F3609CH"
    },
    publicVapidKey:"BKnNEcgB2h9mnzAWtXW1KKWU31qBqbq8n4GmoX9iZjEdf6fClNMsFequSH8xGSHb94-pVN25b-MPXGDk3Hq15nU"
};

export const oldPlantillaCompanyId = [
    "654", "375",
    "3655", //Cia_credit_
    "4274", //Cia_credit
]
