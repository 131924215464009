import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    addPrestamo,
    addPrestamoNota, cancelarPrestamo, deletePrestamoNota,
    editPrestamo,
    getCxC,
    getPrestamos, postPago,
    setIncobrablePrestamo
} from '../../services';
import {INota, IPrestamo, IPrestamoFilter, IPrestamoRes, IPrestamoState} from "../types/IPrestamo";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";
import {IPago, IPagoPost} from "../types/IPago";

const initialState: IPrestamoState = {
    status: "idle"
}

export const mostrarPrestamos = createAsyncThunk(
    'mostrarPrestamos', async (params:IPrestamoFilter|undefined, thunkAPI ) => {
        const {prestamos} = thunkAPI.getState() as AppState;
        let paramsFilter = params || prestamos.filtered;

        const response = await getPrestamos(paramsFilter)
        return {data: response.data as IPrestamoRes, params: response.config.params}
    }
)
export const mostrarCxC = createAsyncThunk(
    'mostrarCxC', async (incluirCxcAldia:boolean,thunkAPI ) => {
        const {rutaId} = (thunkAPI.getState() as AppState)?.dashBoard;

        const response = await getCxC(incluirCxcAldia,rutaId)
        return {data: response.data as IPrestamo[]}
    }
)
export const agregarEditarPrestamo = createAsyncThunk(
    'Prestamos/agregarEditar', async (prestamo: IPrestamo) => {
        const response = await (prestamo.id ? editPrestamo(prestamo) : addPrestamo(prestamo));

        if (response.status === 200 || response.status === 204) {
            historyHelper.replace('/prestamo/'+response.data.id)
        };
        return {data: response.data, edit:!!prestamo.id};
    }
);
export const mostrarPrestamoById = createAsyncThunk(
    'mostrarPrestamoById', async (id:number,thunkAPI) => {
        // let byId = (thunkAPI.getState() as AppState)?.prestamos?.list?.items?.find(c=>c.id === id);
        // if(byId){
        //     return {data: byId}
        // }

        const response = await getPrestamos(id);
        return {data: response.data as IPrestamo}
    }
)
export const agregarNota = createAsyncThunk(
    'agregarNota', async (params:INota) => {
        const response = await addPrestamoNota(params);
        if (response.status === 200 || response.status === 204) {
            historyHelper.back()
        };

        return {data: response.data};
    }
);

export const eliminarNota = createAsyncThunk(
    'prestamo/eliminarNota', async (params:{prestamoId:number, id:number}) => {
        await deletePrestamoNota(params.prestamoId,params.id);
        return params.id;
    }
);
export const agregarPago = createAsyncThunk(
    'prestamo/agregarPago', async (pago:IPagoPost, thunkAPI) => {
        const response = await postPago(pago);
        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(mostrarPrestamoById(pago.prestamoId!))
        };

        return {data: response.data};
    }
);
export const cancelPrestamo = createAsyncThunk(
    'prestamo/cancelPrestamo', async ({id,preComentario,anularPagos}:{id:number,preComentario?:string,anularPagos?:boolean}) => {
        const response = await cancelarPrestamo(id,preComentario,anularPagos);
        return {data: response.data};
    }
);
export const marcarPrestamoIncobrable = createAsyncThunk(
    'prestamo/marcarPrestamoIncobrable', async (id:number) => {
        const response = await setIncobrablePrestamo(id);
        return {data: response.data};
    }
);
const slice = createSlice({
    name: 'mostrarPrestamos',
    initialState,
    reducers: {
        updatePago(state,action: PayloadAction<IPago>) {

            if(state.byId?.pagos?.length){
                state.byId = {
                    ...state.byId,
                    pagos: state.byId.pagos.map(p=>{
                        if(p.id === action.payload.id){
                            return {...p, ...action.payload}
                        }
                        return p
                    })
                }
            }
        },
    },
    extraReducers: builder => {
        //--mostrarPrestamos
        builder.addCase(mostrarPrestamos.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPrestamos.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPrestamos.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarCxC
        builder.addCase(mostrarCxC.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCxC.fulfilled, (state,action ) => {
            state.status = "idle";
            state.cxc = action.payload.data;
        })
        builder.addCase(mostrarCxC.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarPrestamo
        builder.addCase(agregarEditarPrestamo.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPrestamo.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data
            // if(state.list){
            //     state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
            //     state.list.items.unshift(action.payload.data);
            //     if(!action.payload.edit){
            //         ++state.list.pageSize
            //         ++state.list.totalCount
            //     }
            //
            // }
        })
        builder.addCase(agregarEditarPrestamo.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarPrestamoById
        builder.addCase(mostrarPrestamoById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPrestamoById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarPrestamoById.rejected, (state ) => {
            state.status = "idle";
        })

        //--cancelPrestamo
        builder.addCase(cancelPrestamo.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(cancelPrestamo.fulfilled, (state,action ) => {
            state.status = "idle";
            const {data} = action.payload;
           state.byId = data;
            // if(state.list?.items){
            //     state.list.items = state.list?.items.filter(e => e.id !== id)
            // }
        })
        builder.addCase(cancelPrestamo.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarPago
        builder.addCase(agregarPago.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarPago.fulfilled, (state,action ) => {
            state.status = "idle";
        })
        builder.addCase(agregarPago.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarNota
        builder.addCase(agregarNota.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarNota.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            const notas = [...state.byId?.notas ?? []];
            notas.push(data);

            state.byId = {...state.byId, notas}


        })
        builder.addCase(agregarNota.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarNota
        builder.addCase(eliminarNota.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarNota.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            const notas = [...state.byId?.notas?.filter(n=>n.id !== id) ?? []];

            state.byId = {...state.byId, notas}

        })
        builder.addCase(eliminarNota.rejected, (state ) => {
            state.status = "idle";
        })
        //--marcarPrestamoIncobrable
        builder.addCase(marcarPrestamoIncobrable.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(marcarPrestamoIncobrable.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            state.byId = {...state.byId, ...data}

        })
        builder.addCase(marcarPrestamoIncobrable.rejected, (state ) => {
            state.status = "idle";
        })
    }
});
export const {updatePago} = slice.actions;
export default slice.reducer;
