import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditAhorroTransaccion, delAhorroTransaccion, getAhorroTransaccionById, getAhorrosTransacciones} from '../../services';
import {
    IAhorroTransaccion,
    IAhorroTransaccionRes,
    IAhorroTransaccionFilter,
    IAhorroTransaccionState,
    IAhorroTransaccionPost
} from "../types/IAhorroTransaccion";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";
import {mostrarAhorroById} from "./ahorrosReducers";

const initialState: IAhorroTransaccionState = {
    status: "idle",
}

export const mostrarAhorrosTransacciones = createAsyncThunk(
    'mostrarAhorrosTransacciones', async (params:IAhorroTransaccionFilter|undefined, thunkAPI ) => {
        const {ahorrosTransacciones} = thunkAPI.getState() as AppState;
        let paramsFilter = params || ahorrosTransacciones.filtered;

        const response = await getAhorrosTransacciones(paramsFilter)
        return {data: response.data as IAhorroTransaccionRes, params: response.config.params}
    }
)
export const mostrarAhorroTransaccion = createAsyncThunk(
    'mostrarAhorroTransaccion', async (id:number) => {
        const response = await getAhorroTransaccionById(id);
        return {data: response.data as IAhorroTransaccion}
    }
)
export const agregarEditarAhorroTransaccion = createAsyncThunk(
    'AhorrosTransacciones/agregarEditar', async (ahorro: IAhorroTransaccionPost, thunkAPI) => {
        const response = await addEditAhorroTransaccion(ahorro);
        if (response.status === 200 || response.status === 204) {
            historyHelper.back();
            thunkAPI.dispatch(mostrarAhorroById(response.data.ahorroId))
        }
        return {data: response.data};
    }
);

export const eliminarAhorroTransaccion = createAsyncThunk(
    'ahorroTransaccion/eliminar', async (id: number, thunkAPI) => {
        const {ahorros} = thunkAPI.getState() as AppState;


        const response = await delAhorroTransaccion(id);
        // if (response.status === 200 || response.status === 204) historyHelper.replace("ahorrosTransacciones");
        if (response.status === 200 || response.status === 204) {
            // historyHelper.back();

            if(ahorros.byId?.id) {
                thunkAPI.dispatch(mostrarAhorroById(ahorros.byId?.id))
            }
        }
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarAhorrosTransacciones',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarAhorrosTransacciones
        builder.addCase(mostrarAhorrosTransacciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAhorrosTransacciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAhorrosTransacciones.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarAhorroTransaccion
        builder.addCase(mostrarAhorroTransaccion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAhorroTransaccion.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarAhorroTransaccion.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarEditarAhorroTransaccion
        builder.addCase(agregarEditarAhorroTransaccion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarAhorroTransaccion.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
                state.list.items.unshift(action.payload.data);

                ++state.list.pageSize
                ++state.list.totalCount

            }
        })
        builder.addCase(agregarEditarAhorroTransaccion.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarAhorroTransaccion
        builder.addCase(eliminarAhorroTransaccion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAhorroTransaccion.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarAhorroTransaccion.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
